$hero-bg-color: getvar($colors, "primary", "50");
$header-bg-color: getvar($colors, "neutral", "50");
$header-button-ghost-color: getvar($colors, "neutral", "900");
$footer-bg-color: getvar($colors, "neutral", "50");
$footer-copyright-bg-color: getvar($colors, "primary", "50");
$footer-link-color: getvar($colors, "neutral", "900");
$ai-title-bg-color: getvar($colors, "primary", "50");
$home-grid-button-bg-color: getvar($colors, "neutral", "50");
$home-grid-button-border-color: 2px solid getvar($colors, "neutral", "300");
$home-grid-button-icon-bg-color: #ffc8a1;
$home-grid-button-icon-focus: getvar($colors, "neutral", "900");
$home-grid-content-bg-color: getvar($colors, "primary", "50");

$button: (
  "primary": (
    "bg-color": getvar($colors, "primary-green", "500"),
    "color": getvar($colors, "neutral", "50"),
    "hover-bg-color": getvar($colors, "primary-green", "300"),
    "hover-color": getvar($colors, "neutral", "50"),
    "focus-bg-color": getvar($colors, "primary-green", "600"),
    "focus-color": getvar($colors, "neutral", "50"),
    "disabled-bg-color": getvar($colors, "primary-green", "200"),
    "disabled-color": getvar($colors, "neutral", "50"),
  ),
  "secondary": (
    "bg-color": getvar($colors, "primary", "600"),
    "color": getvar($colors, "neutral", "white"),
    "hover-bg-color": getvar($colors, "primary", "700"),
    "hover-color": getvar($colors, "neutral", "white"),
    "focus-bg-color": getvar($colors, "primary", "500"),
    "focus-color": getvar($colors, "neutral", "white"),
    "disabled-bg-color": getvar($colors, "primary", "200"),
    "disabled-color": getvar($colors, "neutral", "white"),
  ),
  "ghost": (
    "bg-color": transparent,
    "color": getvar($colors, "primary", "500"),
    "hover-bg-color": transparent,
    "hover-color": getvar($colors, "primary", "700"),
    "focus-bg-color": transparent,
    "focus-color": getvar($colors, "primary", "600"),
  ),
  "tertiary": (
    "bg-color": transparent,
    "color": getvar($colors, "primary", "500"),
    "hover-bg-color": getvar($colors, "primary", "100"),
    "hover-color": getvar($colors, "primary", "500"),
    "focus-bg-color": transparent,
    "focus-color": getvar($colors, "primary", "600"),
    "disabled-bg-color": getvar($colors, "neutral", "white"),
    "disabled-color": getvar($colors, "primary", "200"),
  ),
);
